import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Event, NavigationStart, Router} from '@angular/router';
import {MenuPlcSupplier} from './menu-plc-resolver.service';
import {PointDeLivraisonDTO} from '../../core/dtos/point-de-livraison-d-t-o';
import {MenuItem, SelectItem} from 'primeng/api';
import {PointDeLivraisonService} from '../../core/services/entities/point-de-livraison.service';
import {MenusToolbarService} from '../../core/services/gestionmenus/menus-toolbar.service';
import {PrintEffectifService, PrintEffectifSupplier} from '../../core/services/gestion-plc/print-effectif.service';
import {PrintCoutRevientPlatService} from '../../core/services/gestion-plc/print-cout-revient-plat.service';
import {Menu} from "primeng/menu";
import {RepasDTO} from "../../core/dtos/repas-dto";
import {ToastService} from "../../core/services/technique/toast.service";
import {StartPointCommandesClientsEnum} from "../../core/enums/start-point-commandes-clients-enum";
import {CommandesClientService} from "../../core/services/entities/commandes-client.service";
import DatesDetailsFromMenuDTO from "../../core/dtos/planification/dates-details-from-menu-dto";
import Week from "../../core/dtos/week-dto";
import {ConviveDTO} from "../../core/dtos/convive-dto";
import {LoadingService} from "../../core/services/loading.service";
import {MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {TypeEffectifEnum} from "../../core/enums/type-effectif-enum";
import RowEffectifsCommandesClientDTO from "../../core/dtos/planification/row-effectifs-commandes-client-dto";
import DetailEffectifCommandeClientDTO from "../../core/dtos/planification/detail-effectif-commande-client-dto";
import {DatePipe} from "@angular/common";
import * as moment from "moment";
import ViewCommandesEffectifsDTO from "../../core/dtos/planification/views/view-commandes-effectifs-dto";

@Component({
  selector: 'yo-menu-plc',
  templateUrl: './menu-plc.component.html',
  styleUrls: ['./menu-plc.component.scss']
})
export class MenuPlcComponent implements OnInit, OnDestroy {

  subRouteData: Subscription;
  subMonthChange: Subscription;
  subHaveDataToPrint: Subscription;
  subRefreshViewCommandesEffectif: Subscription;
  subSaveEffectivesCommandesClientsFromRow: Subscription;
  subSaveEffectivesCommandesClientsFromDetail: Subscription;
  subRowUpdated: Subscription;
  subDetailUpdated: Subscription;

  mplc: MenuPlcSupplier;
  localeFr;
  week: Week;
  modes: SelectItem[] = [
    {label: 'Prévision', value: TypeEffectifEnum.PREVISIONNEL},
    {label: 'Fabrication', value: TypeEffectifEnum.FABRICATION},
    {label: 'Facturation', value: TypeEffectifEnum.FACTURATION},
  ];
  startPoints: SelectItem[] = [
    {label: 'Point de livraison', value: StartPointCommandesClientsEnum.PLC},
    {label: 'Repas', value: StartPointCommandesClientsEnum.REPAS},
    {label: 'Prestation/Régime', value: StartPointCommandesClientsEnum.PRESTATION},
  ];
  StartPointCommandesClientsEnum = StartPointCommandesClientsEnum;

  selectedPlc: PointDeLivraisonDTO;
  selectedRepas: RepasDTO;
  selectedPrestation: ConviveDTO;
  typeEffectifSelected: TypeEffectifEnum = TypeEffectifEnum.PREVISIONNEL;
  selectedStartPoint: StartPointCommandesClientsEnum = StartPointCommandesClientsEnum.PLC;
  selectedDate: Date = new Date();
  idSelected: number;

  datesCreationMenus: Date[];
  datesSaisieEffectifs: Date[];
  disabledDays: number[];
  dateMin: Date;
  datesDetailsFromMenu: DatesDetailsFromMenuDTO;

  @ViewChild('printMenu') printMenu: Menu;

  itemCr = {
    icon: 'far fa-file-pdf',
    label: 'Calcul du coût de revient d\'un plat',
    command: () => this.openDialogPrintCoutRevientPlat(),
  };

  itemEffectifs = {
    icon: 'far fa-file-excel',
    label: `Effectifs`,
    command: () => this.openDialogPrintEffectifMenu(),
    disabled: true
  };

  itemEffectifsTotaux = {
    icon: 'far fa-file-excel',
    label: `Effectifs totaux`,
    command: () => this.openDialogPrintEffectifMenuTotaux()
  };

  itemEffectifsDetails = {
    icon: 'far fa-file-excel',
    label: `Effectifs avec détail des menus`,
    command: () => this.openDialogPrintEffectifDetailsMenu(),
    disabled: true
  };

  itemsPrintMenu: MenuItem[] = [
    this.itemCr,
    this.itemEffectifs,
    this.itemEffectifsTotaux,
    this.itemEffectifsDetails
  ];

  popupDuplicateEffectivesVisible: boolean = false;
  duplicateToManufacturing: boolean = false;
  duplicateToBilling: boolean = false;

  //Flexibilité
  searchModeOption = 'contains';
  searchExprOption: any = 'libelle';
  startPoint: StartPointCommandesClientsEnum = StartPointCommandesClientsEnum.PLC;
  duplicateStartPoint: StartPointCommandesClientsEnum = StartPointCommandesClientsEnum.PLC;
  endPoint: StartPointCommandesClientsEnum;
  endPointPanel: StartPointCommandesClientsEnum[] = [];

  viewCommandesEffectifs: ViewCommandesEffectifsDTO;
  duplicateViewCommandesEffectifs: ViewCommandesEffectifsDTO;
  cols: Date[] = [];
  timer: any;

  rowsUpdated: RowEffectifsCommandesClientDTO[] = [];
  detailsUpdated: DetailEffectifCommandeClientDTO[] = [];

  loadingVisible: boolean = false;

  constructor(public utils: UtilsService, private route: ActivatedRoute,
              private cd: ChangeDetectorRef,
              private menusToolbarSvc: MenusToolbarService,
              private plcSvc: PointDeLivraisonService,
              private printEffectifService: PrintEffectifService,
              private printCrpService: PrintCoutRevientPlatService,
              private toastSvc: ToastService,
              private commandesClientSvc: CommandesClientService,
              private loadingSvc: LoadingService,
              private datePipe: DatePipe,
              private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) this.saveEffectivesCommandesClients();
    });

    document.addEventListener("visibilitychange", (event) => {
      if (document.visibilityState != "visible") {
        this.saveEffectivesCommandesClients();
      }
    });

    window.addEventListener("beforeunload", (event) => {
      this.saveEffectivesCommandesClients();
      event.returnValue = '';
    });

    this.inactivityTime();
  }

  ngOnInit() {
    this.initHaveDataToPrintSubscription();
    this.menuMonthChangeSubscription();
    this.routeDataSubscription();
    this.refreshViewCommandeClientSubscription();
    this.saveEffectifsCommandesClientsSubscription();
    this.rowUpdatedSubscription();
    this.detailUpdatedSubscription();
    this.updateDatesBySelection(null);
    this.initEndPoint();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subRouteData);
    this.utils.unsubscribe(this.subMonthChange);
    this.utils.unsubscribe(this.subHaveDataToPrint);
    this.utils.unsubscribe(this.subRefreshViewCommandesEffectif);
    this.utils.unsubscribe(this.subSaveEffectivesCommandesClientsFromRow);
    this.utils.unsubscribe(this.subSaveEffectivesCommandesClientsFromDetail);
    this.utils.unsubscribe(this.subRowUpdated);
    this.utils.unsubscribe(this.subDetailUpdated);
  };

  routeDataSubscription = () => {
    this.subRouteData = this.route.data
      .subscribe((data: { menuPlcSupplier: MenuPlcSupplier }) => this.mplc = data.menuPlcSupplier);
  };

  /**
   * Subscription qui permet de rafraîchir les données du calendrier selon la date sélectionnée
   */
  menuMonthChangeSubscription = () => {
    this.subMonthChange = this.commandesClientSvc.menuMonthChange$.subscribe(item => {
      this.commandesClientSvc.getMonthDates(item[0], this.selectedDate, this.idSelected, this.startPoint)
        .subscribe(response => {
          if (response.one) this.initMenu(response.one, item[0], item[1]);
        })
    });
  };

  /**
   * Méthode qui initialise les colonnes selon la date sélectionnée par l'utilisateur
   */
  initCols = () => {
    this.cols = [];
    this.datesDetailsFromMenu.joursDisponibles.sort((a, b) => a - b)
    let day: Date = new Date(this.week.weekMonday);
    this.cols.push(day);
    this.datesDetailsFromMenu.joursDisponibles.forEach(jour => {
      let newDay: Date = this.utils.addDaysToDate(day, jour);
      if (newDay.getTime() <= this.week.weekSunday.getTime() && this.datesDetailsFromMenu.joursDisponibles.find(j => newDay.getDay() === j)) this.cols.push(newDay);
    });
    if (this.datesDetailsFromMenu.joursDisponibles.find(j => j === 7)) this.cols.push(new Date(this.week.weekSunday));
    this.cols.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }

  openDialogDuplicateEffectivesPrevisionnals = (): void => {
    this.saveEffectivesCommandesClients();

    this.popupDuplicateEffectivesVisible = true;
  }

  closeDialogDuplicateEffectivesPrevisionnals = (): void => {
    this.duplicateToManufacturing = false;
    this.duplicateToBilling = false;
    this.popupDuplicateEffectivesVisible = false;
  }

  /**
   * Méthode pour dupliquer les effectifs
   */
  duplicateEffectivesPrevisionnals = (): void => {
    if (this.viewCommandesEffectifs) {
      const typesEffectifsSelected: TypeEffectifEnum[] = [];
      if (this.duplicateToManufacturing) typesEffectifsSelected.push(TypeEffectifEnum.FABRICATION);
      if (this.duplicateToBilling) typesEffectifsSelected.push(TypeEffectifEnum.FACTURATION);
      this.declareIdSelected();

      this.commandesClientSvc.duplicateEffectifsCommandesClients(this.week.weekMonday, this.week.weekSunday, this.idSelected,
        this.startPoint, this.endPoint, this.viewCommandesEffectifs, typesEffectifsSelected)
        .subscribe(response => {
          if (!this.utils.isNullOrEmpty(response) && !this.utils.isNullOrEmpty(response.one)) {
            this.viewCommandesEffectifs = response.one;
            this.duplicateViewCommandesEffectifs = JSON.parse(JSON.stringify(response.one));
            this.commandesClientSvc.announceViewCommandesEffectif(this.viewCommandesEffectifs);
            this.printEffectifService.announceHaveDataToPrint(!this.utils.isNullOrEmpty(this.viewCommandesEffectifs));
            this.commandesClientSvc.announceChangeEffectifsCommandesClients();
            this.closeDialogDuplicateEffectivesPrevisionnals();
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Effectif(s) dupliqué(s) avec succès`);
          } else {
            this.viewCommandesEffectifs = null;
            this.commandesClientSvc.announceViewCommandesEffectif(null);
          }
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez sélectionner un point de livraison ayant un menu existant pour une période donnée`);
    }
  }

  /**
   * Subscription qui permet de rafraîchir la vue du planning des effectifs
   */
  refreshViewCommandeClientSubscription = () => {
    this.subRefreshViewCommandesEffectif = this.commandesClientSvc.refreshViewCommandesEffectif$
      .subscribe(() => {
        this.viewCommandesEffectifs = null;
        if (this.idSelected) this.getViewEffectifCommandeClient();
      });
  }

  declareIdSelected = () => {
    if (this.selectedPlc) this.idSelected = this.selectedPlc.id;
    if (this.selectedRepas) {
      this.commandesClientSvc.announceSelectedRepas(this.selectedRepas);
      this.idSelected = this.selectedRepas.id;
    }
    if (this.selectedPrestation) this.idSelected = this.selectedPrestation.id;
  }

  /**
   * Méthode qui renvoie la vue demandée par l'utilisateur par rapport à ses choix (la date sélectionnée, le point de départ,
   * le second point d'entrée, et l'identifiant sélectionné par rapport au point de départ)
   */
  getViewEffectifCommandeClient = () => {
    this.loadingVisible = true;

    this.commandesClientSvc.loadViewCommandesEffectif(this.week.weekMonday, this.week.weekSunday, this.idSelected,
      this.startPoint, this.endPoint).subscribe(response => {
      if (!this.utils.isNullOrEmpty(response) && !this.utils.isNullOrEmpty(response.one) && this.utils.isNullOrEmpty(response.one.empty)) {
        this.viewCommandesEffectifs = response.one;
        this.duplicateViewCommandesEffectifs = JSON.parse(JSON.stringify(response.one));
        this.commandesClientSvc.announceViewCommandesEffectif(this.viewCommandesEffectifs);
        this.commandesClientSvc.announceChangeEffectifsCommandesClients();
        this.printEffectifService.announceHaveDataToPrint(!this.utils.isNullOrEmpty(this.viewCommandesEffectifs));
      } else {
        this.viewCommandesEffectifs = null;
        this.commandesClientSvc.announceViewCommandesEffectif(this.viewCommandesEffectifs);
      }
      this.loadingVisible = false;
    });
  }

  /**
   * Méthode utilisée lorsque l'utilisateur change de type d'effectif
   */
  changeTypeEffectif = () => {
    this.commandesClientSvc.announceSelectedTypeEffectif(this.typeEffectifSelected);
  };

  openDialogPrintCoutRevientPlat = () => {
    this.saveEffectivesCommandesClients();

    this.printCrpService.announceDisplayDialogPrintCoutRevientPlat(true);
  }

  openDialogPrintEffectifMenu = () => {
    this.saveEffectivesCommandesClients();

    const pes = new PrintEffectifSupplier(this.selectedPlc, this.selectedDate, this.viewCommandesEffectifs, true, false,
      this.week.weekMonday, this.week.weekSunday, this.startPoint, this.endPoint, this.idSelected);
    this.printEffectifService.announceDisplayDialogPrintEffectif(pes);
  };

  openDialogPrintEffectifDetailsMenu = () => {
    this.saveEffectivesCommandesClients();

    const pes = new PrintEffectifSupplier(this.selectedPlc, this.selectedDate, this.viewCommandesEffectifs, true, true,
      this.week.weekMonday, this.week.weekSunday, this.startPoint, this.endPoint, this.idSelected);
    this.printEffectifService.announceDisplayDialogPrintEffectif(pes);
  };

  openDialogPrintEffectifMenuTotaux = (): void => {
    this.saveEffectivesCommandesClients();

    this.printEffectifService.announceDisplayDialogPrintEffectifTotaux({
      dates: this.selectedDate
    });
  }

  /**
   * Subscription pour initialiser le menu proposé aux utilisateur pour imprimer certaines données concernant
   * le planning des effectifs
   */
  initHaveDataToPrintSubscription = () => {
    this.subHaveDataToPrint = this.printEffectifService.haveDataToPrint$.subscribe((hasData: boolean) => {
      this.itemEffectifs.disabled = !hasData;
      this.itemEffectifsDetails.disabled = !hasData;

      this.itemsPrintMenu = [this.itemCr, this.itemEffectifs, this.itemEffectifsTotaux, this.itemEffectifsDetails];
    });
  };

  /**
   * Subscription qui reçoit les informations nécessaires pour modifier un effectif ou un taux de prise, par
   * rapport à une ligne ou à un détail
   */
  saveEffectifsCommandesClientsSubscription = () => {
    this.subSaveEffectivesCommandesClientsFromRow =
      this.commandesClientSvc.saveEffectifsCommandesClientsFromRow$
        .subscribe((cells: RowEffectifsCommandesClientDTO[]) => {
          this.commandesClientSvc.saveEffectifsCommandesClients(this.idSelected, this.duplicateStartPoint,
            this.endPoint, this.duplicateViewCommandesEffectifs, cells, this.detailsUpdated, null, null)
            .subscribe(response => {
              if (!this.utils.isNullOrEmpty(response) && !this.utils.isNullOrEmpty(response.one)) {
                this.commandesClientSvc.announceRefreshViewCommandesEffectif();
                this.commandesClientSvc.announceChangeEffectifsCommandesClients();
              } else {
                this.viewCommandesEffectifs = null;
                this.commandesClientSvc.announceViewCommandesEffectif(null);
              }
            })
        });

    this.subSaveEffectivesCommandesClientsFromDetail =
      this.commandesClientSvc.saveEffectifsCommandesClientsFromDetail$.subscribe((item: [boolean, boolean, DetailEffectifCommandeClientDTO[]]) => {
        if (this.idSelected && this.viewCommandesEffectifs) {
          this.commandesClientSvc.saveEffectifsCommandesClients(this.idSelected, this.startPoint,
            this.endPoint, this.duplicateViewCommandesEffectifs, this.rowsUpdated, item[2], item[0], item[1])
            .subscribe(response => {
              if (!this.utils.isNullOrEmpty(response) && !this.utils.isNullOrEmpty(response.one)) {
                this.commandesClientSvc.announceRefreshViewCommandesEffectif();
                this.commandesClientSvc.announceChangeEffectifsCommandesClients();
              } else {
                this.viewCommandesEffectifs = null;
                this.commandesClientSvc.announceViewCommandesEffectif(null);
              }
            })
        }
      })
  }


  /**
   * Méthode utilisée lorsqu'on sélectionne un point de départ dans la liste de sélection
   */
  selectStartPoint = ($event: any) => {
    this.saveEffectivesCommandesClients();

    if ($event.value) {
      this.commandesClientSvc.announceDetailsEffectifCommandeClient(null);
      this.commandesClientSvc.announceLoadingView(true);
      this.declareIdSelected();
      this.commandesClientSvc.announceMenuMonthChange(null, false);
    }
  }

  /**
   * Méthode utilisée lorsqu'on modifie le point de départ, qui permet de tout réinitialiser
   * en attendant que l'utilisateur sélectionne un point de départ dans la nouvelle liste de
   * sélection
   */
  updateStartPoint = () => {
    this.saveEffectivesCommandesClients();

    this.selectedPlc = null;
    this.selectedRepas = null;
    this.selectedPrestation = null;
    this.idSelected = null;
    this.endPoint = null;

    this.initEndPoint();

    this.commandesClientSvc.announceMenuMonthChange(null, false);

    this.commandesClientSvc.announceViewCommandesEffectif(null);

    this.commandesClientSvc.announceDetailsEffectifCommandeClient(null);
  }

  /**
   * Méthode qui initialise les seconds points d'entrées par rapport au point de départ sélectionné
   * par l'utilisateur
   */
  initEndPoint = () => {
    switch (this.startPoint) {
      case StartPointCommandesClientsEnum.PLC:
        if (!this.endPoint) {
          this.endPoint = StartPointCommandesClientsEnum.REPAS;
          this.commandesClientSvc.announceEndPointUpdated(this.endPoint);
        }
        this.endPointPanel = [StartPointCommandesClientsEnum.REPAS, StartPointCommandesClientsEnum.PRESTATION];
        break;
      case StartPointCommandesClientsEnum.REPAS:
        if (!this.endPoint) {
          this.endPoint = StartPointCommandesClientsEnum.PLC;
          this.commandesClientSvc.announceEndPointUpdated(this.endPoint);
        }
        this.endPointPanel = [StartPointCommandesClientsEnum.PLC, StartPointCommandesClientsEnum.PRESTATION];
        break;
      case StartPointCommandesClientsEnum.PRESTATION:
        if (!this.endPoint) {
          this.endPoint = StartPointCommandesClientsEnum.PLC;
          this.commandesClientSvc.announceEndPointUpdated(this.endPoint);
        }
        this.endPointPanel = [StartPointCommandesClientsEnum.PLC, StartPointCommandesClientsEnum.REPAS];
        break;
    }
  }

  /**
   * Méthode qui modifie la vue selon le choix de l'utilisateur en ce qui concerne le second point d'entrée
   */
  updateEndPoint = ($event: any) => {
    if (this.detailsUpdated.length == 0 && this.rowsUpdated.length == 0) {
      this.endPoint = $event.itemData;
      this.viewCommandesEffectifs = null;

      this.commandesClientSvc.announceLoadingView(true);
      this.commandesClientSvc.announceRefreshViewCommandesEffectif();
    } else {
      this.saveEffectivesCommandesClients();

      this.endPoint = $event.itemData;
      this.viewCommandesEffectifs = null;
    }

    this.commandesClientSvc.announceEndPointUpdated(this.endPoint);
    this.commandesClientSvc.announceDetailsEffectifCommandeClient(null);

  }

  /**
   * Méthode utilisée lorsque l'utilisateur sélectionne une date
   */
  updateDatesBySelection = ($event: any) => {
    this.saveEffectivesCommandesClients();

    if ($event) this.selectedDate = $event.value;

    this.commandesClientSvc.announceViewCommandesEffectif(null);
    this.commandesClientSvc.announceDetailsEffectifCommandeClient(null);
    this.commandesClientSvc.announceLoadingView(true);

    this.week = {
      weekInYear: this.utils.getWeek(this.selectedDate),
      weekMonday: this.utils.getFirstDayOfWeek(this.selectedDate),
      weekSunday: this.utils.getLastDayOfWeek(this.selectedDate),
      weekYear: this.selectedDate.getFullYear()
    };

    this.cd.detectChanges();

    this.commandesClientSvc.announceMenuMonthChange(null, false);
  }

  /**
   * Méthode utilisée lorsque l'utilisateur ne sélectionne pas de date
   */
  updtateDatesByClickOutside = () => this.commandesClientSvc.announceMenuMonthChange(null, true);

  /**
   * Méthode utilisée lorsque l'utilisateur change de mois sur le calendrier proposé pour
   * sélectionner une date
   */
  updateDatesByMonth = ($event: any) => this.commandesClientSvc.announceMenuMonthChange($event, false);

  /**
   * Méthode qui initialise les données du menu
   */
  initMenu = (response: DatesDetailsFromMenuDTO, event: any, clickOutside: boolean) => {
    this.datesDetailsFromMenu = response;
    this.disabledDays = this.utils.getDisabledDays(response.joursDisponibles);

    this.datesSaisieEffectifs = response.datesSaisieEffectifs;
    this.datesCreationMenus = response.datesCreationMenus;
    this.dateMin = response.dateMin;

    this.dateMin = this.utils.convertNumberDateToDate(this.dateMin);

    if (this.datesSaisieEffectifs?.length) {
      this.datesSaisieEffectifs = this.datesSaisieEffectifs.map(item => this.utils.convertNumberDateToDate(item));
    }

    if (this.datesCreationMenus?.length)
      this.datesCreationMenus = this.datesCreationMenus.map(item => this.utils.convertNumberDateToDate(item));

    if (!event) {
      this.commandesClientSvc.announceDatesDetailsFromMenu(response);

      if (!clickOutside) {
        this.initCols();
        this.commandesClientSvc.announceRefreshViewCommandesEffectif();
      }
    }
  };

  /**
   * Méthode qui permet de formatter la date sur le calendrier proposé à l'utilisateur
   */
  getWeekLabel = (week: Week): string => this.utils.getWeek(week.weekMonday) + ' ' + this.datePipe.transform(week.weekMonday, 'dd/MM/yyyy')
    + ' au ' + this.datePipe.transform(week.weekSunday, 'dd/MM/yyyy');

  /**
   * Méthode qui permet de récupérer les cellules 'row' modifiées et de les stocker avant de les sauvegarder
   */
  rowUpdatedSubscription = () => {
    this.subRowUpdated = this.commandesClientSvc.rowUpdated$.subscribe((cell: RowEffectifsCommandesClientDTO) => {
      cell = this.updateDetails(cell);

      let idxItemFound: number = this.rowsUpdated.findIndex(c => c.cmcrPlcDate?.id === cell.cmcrPlcDate?.id);
      if (idxItemFound > -1)
        this.rowsUpdated[idxItemFound] = cell;
      else
        this.rowsUpdated.push(Object.assign({}, cell));
    })
  }

  /**
   * Méthode qui permet de récupérer les cellules 'detail' modifiées et de les stocker avant de les sauvegarder
   */
  detailUpdatedSubscription = () => {
    this.subDetailUpdated = this.commandesClientSvc.detailUpdated$.subscribe((cell: DetailEffectifCommandeClientDTO) => {
      let idxItemFound: number = this.detailsUpdated.findIndex(c => c.mcPlc?.id === cell.mcPlc?.id);
      if (idxItemFound > -1)
        this.detailsUpdated[idxItemFound] = cell;
      else
        this.detailsUpdated.push(Object.assign({}, cell));
    })
  }

  /**
   * Méthode qui modifier les détails d'une cellule suite à la modification de l'effectif de celle-ci
   */
  updateDetails = (cell: RowEffectifsCommandesClientDTO): RowEffectifsCommandesClientDTO => {
    cell.details.forEach(detail => {
      switch (detail.typeEffectif) {
        case TypeEffectifEnum.PREVISIONNEL:
          detail.effectifPrevu = Math.round((detail.tauxPrise * cell.effectifPrevisionnel) / 100);
          detail.arrondiEffectifPrevu = false;
          break;
        case TypeEffectifEnum.FABRICATION:
          detail.effectifPrevu = Math.round((detail.tauxPrise * cell.effectifFabrication) / 100);
          detail.arrondiEffectifPrevu = false;
          break;
        case TypeEffectifEnum.FACTURATION:
          detail.effectifPrevu = Math.round((detail.tauxPrise * cell.effectifFacturation) / 100);
          detail.arrondiEffectifPrevu = false;
          break;
      }
    });

    return cell;
  }

  /**
   * Méthode qui permet de lancer la sauvegarde des effectifs
   */
  saveEffectivesCommandesClients = () => {
    if (this.rowsUpdated.length) {
      this.commandesClientSvc.announceSaveEffectifsCommandesClientsFromRow(this.rowsUpdated);
      this.rowsUpdated = [];
    } else {
      if (this.detailsUpdated.length) {
        this.commandesClientSvc.announceSaveEffectifsCommandesClientsFromDetail(null, null, this.detailsUpdated);
        this.detailsUpdated = [];
      }
    }
    this.duplicateStartPoint = this.startPoint;
  }

  inactivityTime = () => {
    let cur = this;
    let time;
    const timeout: number = 60000;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    function save() {
      cur.saveEffectivesCommandesClients();
    }

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(save, timeout);
    }
  }

  onOptionChanged = ($event: any) => {
    if ($event.name === 'currentDate') {
      const previousDateMoment = moment($event.previousValue);
      const dateMoment = moment($event.value);
      if (previousDateMoment.isBefore(dateMoment)) {
        dateMoment.set('month', dateMoment.month() - 1);
      }
      this.commandesClientSvc.announceMenuMonthChange(dateMoment.toDate(), false);
    }
  };

  convertToDate = (date: string) => new Date(date);

  onItemClick = ($event: any) => {
    console.log($event)
  };
}
