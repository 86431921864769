// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[e8b4733575df0ab25f00a88eb29b95cb8067930b]6.1-10856(e8b4733575)-C22/10/2024-12:31:35-B31/10/2024-12:15:06' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[e8b4733575df0ab25f00a88eb29b95cb8067930b]6.1-10856(e8b4733575)-C22/10/2024-12:31:35-B31/10/2024-12:15:06",
  branch: "e8b4733575df0ab25f00a88eb29b95cb8067930b",
  latestTag: "6.1",
  revCount: "10856",
  shortHash: "e8b4733575",
  longHash: "e8b4733575df0ab25f00a88eb29b95cb8067930b",
  dateCommit: "22/10/2024-12:31:35",
  dateBuild: "31/10/2024-12:15:06",
  buildType: "Ansible",
  } ;
